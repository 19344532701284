<template>
  <ProfileImage
    :size="size"
    :image="avatar.image"
    class="my-1"
    :alt="avatar.name"
  />
  <!-- <v-avatar :class="customClass ? customClass : ''" :size="size">
    <slot />
    <img
      :alt="avatar.name"
      :src="getProfileImage(avatar.image)"
      @error="replaceDefaultUserImage"
    />
  </v-avatar> -->
</template>

<script>
export default {
  props: {
    avatar: {
      default: () => {},
      type: Object
    },
    customClass: {
      default: "",
      type: String
    },
    size: {
      default: "48px",
      type: String
    }
  }
};
</script>

<style lang="scss" scoped></style>
