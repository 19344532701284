<template>
  <div>
    <div v-if="getInactiveUserMessage || getIsNetworkError">
      <no-data
        v-if="getIsUserDisabled"
        :first-text="getInactiveUserMessage"
      ></no-data>
      <vue-no-data v-else :message="''"></vue-no-data>
    </div>
    <div v-else>
      <page-wrapper :bread-crumbs="breadCrumbs" from-app-page>
        <div class="dashboard_main side-btn-container mt-1">
          <v-row v-if="getApps" class="mb-12">
            <v-col
              cols="12"
              md="4"
              lg="4"
              :key="index"
              v-for="(app, index) in getApps"
            >
              <v-card
                class="apps-card radius-10"
                outlined
                id="appCard"
                @click="navigateToAppInfo($event, app.id)"
              >
                <div
                  v-if="app.join"
                  @click.stop="joinApp(app)"
                  class="overlay px-3 py-2"
                >
                  <v-btn
                    :loading="loading"
                    color="primary"
                    x-large
                    class="text-transform-none"
                    >Join</v-btn
                  >
                </div>
                <v-card-title>
                  <img
                    :alt="app.name"
                    :src="getAppImage(app)"
                    width="50"
                    @error="replaceDefaultAppImage"
                  />

                  <span class="mx-4">{{ app.name }}</span>
                  <v-spacer />
                  <v-tooltip
                    bottom
                    transition="slide-y-transition"
                    v-if="canManageApp"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        small
                        v-on="on"
                        :color="app.public ? 'primary' : 'success'"
                        v-text="
                          app.public
                            ? 'mdi-lock-open-outline'
                            : 'mdi-lock-outline'
                        "
                      ></v-icon>
                    </template>
                    <span>
                      {{
                        app.public
                          ? "Can be accessed publicly"
                          : "Accessed by your team members only"
                      }}
                    </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text class="pb-0 dark-grey-text">
                  <v-row>
                    <v-col cols="6" md="4">
                      <h3 class="mb-3">Releases</h3>
                      <h3>
                        {{ get(app, "info.total_releases", 0) }}
                      </h3>
                    </v-col>
                    <v-col cols="6" md="4" v-if="canManageApp">
                      <h3 class="mb-3">Feedback</h3>
                      <h3>
                        {{ get(app, "info.total_feedback", 0) }}
                      </h3>
                    </v-col>
                    <v-col cols="6" md="4">
                      <h3 class="mb-3">Platforms</h3>
                      <PlatformIcon
                        android
                        :color="
                          app.platforms.indexOf('android') === -1
                            ? 'grey'
                            : 'primary'
                        "
                      />
                      <span class="mx-1"></span>
                      <PlatformIcon
                        apple
                        :color="
                          app.platforms.indexOf('ios') === -1
                            ? 'grey'
                            : 'primary'
                        "
                      />
                    </v-col>
                    <!-- <v-col
                      v-if="!isEmpty(app.storage)"
                      cols="4"
                      :class="
                        $vuetify.breakpoint.smAndDown ? '' : 'text-center'
                      "
                      md="4"
                    >
                      <h3 class="mb-3">Storage</h3>
                      <v-tooltip
                        bottom
                        transition="slide-y-reverse-transition"
                        v-if="canManageTeam"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            alt="app-img"
                            class="mb-n1"
                            height="24"
                            v-bind="attrs"
                            v-on="on"
                            :src="getStorageImg(get(app, 'storage.icon', null))"
                          />
                        </template>
                        <span>
                          {{ get(app, "storage.name", null) }}
                        </span>
                      </v-tooltip>
                    </v-col> -->
                  </v-row>
                  <v-row class="pb-2">
                    <v-col cols="8" md="12" sm="4">
                      <h3 class="mb-3">Members</h3>
                      <AvatarGroup
                        :data="app.members.data"
                        :total="app.members.total"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div
            v-if="!appsLoader && !getApps.length"
            class="d-flex justify-center align-center mt-15 px-6"
          >
            <no-data
              icon="/media/placeholders/no_apps.svg"
              first-text="No apps"
              second-text="Apps will be holding your releases to be installed from our mobile"
            ></no-data>
          </div>

          <v-row v-if="appsLoader" class="py-5">
            <v-col cols="12" md="6" lg="4" v-for="i in 6" :key="i">
              <v-skeleton-loader type="list-item-avatar-two-line, list-item" />
            </v-col>
          </v-row>
        </div>
        <infinate-loading-base
          v-if="!getInactiveUserMessage"
          :endpoint="endpoint"
          :reset="true"
          @setInfiniteResponse="setInfiniteResponse"
          @resetInfiniteResponse="resetInfiniteResponse"
        ></infinate-loading-base>
      </page-wrapper>
    </div>
  </div>
</template>

<script>
import AvatarGroup from "@/view/components/Team/Members/AvatarGroup/AvatarGroup";
import { isEmpty, get } from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import { GET_APPS, JOIN_APP, SEARCH } from "@/store/apps/apps.module";
import InfinateLoadingBase from "@/view/components/Common/InfinateLoadingBase.vue";
import VueNoData from "@/view/components/Common/VueNoData";

export default {
  name: "apps",
  data() {
    return {
      get,
      isEmpty,
      search: "",
      endpoint: GET_APPS,
      breadCrumbs: [{ text: "Apps", disabled: false, to: { name: "apps" } }],
      // in case search not found
      noSearchFound: null,
      loading: false,
      errorMessage: null,
      noApps: false,
    };
  },
  computed: {
    ...mapState({
      apps: (state) => state.apps.filteredApps,
      totalApps: (state) => state.apps.totalApps,
      appsLoader: (state) => state.apps.appsLoading,
    }),
    ...mapGetters({
      getApps: "getApps",
      getInactiveUserMessage: "getInactiveUserMessage",
      getIsNetworkError: "getIsNetworkError",
    }),
    lastId() {
      return this.apps && this.apps.length
        ? this.apps[this.apps.length - 1].id
        : "";
    },
  },
  components: {
    VueNoData,
    InfinateLoadingBase,
    AvatarGroup,
  },
  created() {
    this.clearAppResponse();
  },
  methods: {
    ...mapMutations({
      setAppResponse: "setAppResponse",
      clearAppResponse: "clearAppResponse",
    }),
    setInfiniteResponse(data) {
      this.setAppResponse(data.data);
    },
    resetInfiniteResponse() {
      this.clearAppResponse();
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    navigateToAppInfo(event, app_id) {
      event.preventDefault();
      if (this.$route.name !== "app-releases") {
        this.$router
          .push({
            name: "app-releases",
            params: {
              app_id: app_id,
            },
          })
          .catch(() => {});
      }
    },
    joinApp(app) {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(JOIN_APP, app.id)
        .then((response) => {
          this.loading = false;
          this.notifyUserMessage(response.message);
          this.$router.push({
            name: "app-dashboard",
            params: { app_id: app.id },
          });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    onSearch() {
      this.$store.dispatch(SEARCH, this.search);
      isEmpty(this.apps)
        ? (this.noSearchFound = true)
        : (this.noSearchFound = false);
    },
  },
};
</script>

<style scoped>
.overlay {
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(181, 181, 195, 0.3);
}
</style>
