var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getInactiveUserMessage || _vm.getIsNetworkError)?_c('div',[(_vm.getIsUserDisabled)?_c('no-data',{attrs:{"first-text":_vm.getInactiveUserMessage}}):_c('vue-no-data',{attrs:{"message":''}})],1):_c('div',[_c('page-wrapper',{attrs:{"bread-crumbs":_vm.breadCrumbs,"from-app-page":""}},[_c('div',{staticClass:"dashboard_main side-btn-container mt-1"},[(_vm.getApps)?_c('v-row',{staticClass:"mb-12"},_vm._l((_vm.getApps),function(app,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-card',{staticClass:"apps-card radius-10",attrs:{"outlined":"","id":"appCard"},on:{"click":function($event){return _vm.navigateToAppInfo($event, app.id)}}},[(app.join)?_c('div',{staticClass:"overlay px-3 py-2",on:{"click":function($event){$event.stopPropagation();return _vm.joinApp(app)}}},[_c('v-btn',{staticClass:"text-transform-none",attrs:{"loading":_vm.loading,"color":"primary","x-large":""}},[_vm._v("Join")])],1):_vm._e(),_c('v-card-title',[_c('img',{attrs:{"alt":app.name,"src":_vm.getAppImage(app),"width":"50"},on:{"error":_vm.replaceDefaultAppImage}}),_c('span',{staticClass:"mx-4"},[_vm._v(_vm._s(app.name))]),_c('v-spacer'),(_vm.canManageApp)?_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":app.public ? 'primary' : 'success'},domProps:{"textContent":_vm._s(
                        app.public
                          ? 'mdi-lock-open-outline'
                          : 'mdi-lock-outline'
                      )}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(app.public ? "Can be accessed publicly" : "Accessed by your team members only")+" ")])]):_vm._e()],1),_c('v-card-text',{staticClass:"pb-0 dark-grey-text"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Releases")]),_c('h3',[_vm._v(" "+_vm._s(_vm.get(app, "info.total_releases", 0))+" ")])]),(_vm.canManageApp)?_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Feedback")]),_c('h3',[_vm._v(" "+_vm._s(_vm.get(app, "info.total_feedback", 0))+" ")])]):_vm._e(),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Platforms")]),_c('PlatformIcon',{attrs:{"android":"","color":app.platforms.indexOf('android') === -1
                          ? 'grey'
                          : 'primary'}}),_c('span',{staticClass:"mx-1"}),_c('PlatformIcon',{attrs:{"apple":"","color":app.platforms.indexOf('ios') === -1
                          ? 'grey'
                          : 'primary'}})],1)],1),_c('v-row',{staticClass:"pb-2"},[_c('v-col',{attrs:{"cols":"8","md":"12","sm":"4"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Members")]),_c('AvatarGroup',{attrs:{"data":app.members.data,"total":app.members.total}})],1)],1)],1)],1)],1)}),1):_vm._e(),(!_vm.appsLoader && !_vm.getApps.length)?_c('div',{staticClass:"d-flex justify-center align-center mt-15 px-6"},[_c('no-data',{attrs:{"icon":"/media/placeholders/no_apps.svg","first-text":"No apps","second-text":"Apps will be holding your releases to be installed from our mobile"}})],1):_vm._e(),(_vm.appsLoader)?_c('v-row',{staticClass:"py-5"},_vm._l((6),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line, list-item"}})],1)}),1):_vm._e()],1),(!_vm.getInactiveUserMessage)?_c('infinate-loading-base',{attrs:{"endpoint":_vm.endpoint,"reset":true},on:{"setInfiniteResponse":_vm.setInfiniteResponse,"resetInfiniteResponse":_vm.resetInfiniteResponse}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }