<template>
  <section class="avatars-group stacked" style="overflow:visible">
    <div
      :key="avatar.id"
      v-for="avatar in avatarsStackedLimited"
      class="avatars-group__item"
    >
      <v-tooltip bottom transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="pointer">
            <profile-avatar size="30" :avatar="avatar" />
          </span>
        </template>
        <span class="font-weight-medium">{{ avatar.name }}</span>
      </v-tooltip>
    </div>
    <!-- <v-avatar
      v-if="total - avatarsSorted.length"
      size="30"
      class="primary"
    >
      <span class="bold-text white--text"> +{{ total - avatarsSorted.length }} </span>
    </v-avatar> -->
    <!-- <div class="avatars-group__item more">
      <v-menu
        transition="slide-y-transition"
        v-model="stackedMenu"
        left
        :max-height="menuMaxHeight"
        nudge-bottom="8"
        offset-y
      >
        <template
          #activator="{ attrs, on }"
          v-if="total - avatarsSorted.length"
        >
          <v-btn small icon class="primary" dark v-bind="attrs" v-on="on">
            +{{ total - avatarsSorted.length }}
          </v-btn>
        </template>
        <v-list dense class="pa-2">
          <v-list-item
            class="px-0"
            v-for="(avatar, i) in avatarsSorted"
            :key="i"
          >
            <profile-avatar size="30" :avatar="avatar" />
            <v-list-item-title class="pl-2" v-text="avatar.name" />
          </v-list-item>
        </v-list>
      </v-menu>
    </div> -->
  </section>
</template>

<script>
import ProfileAvatar from "@/view/components/Team/Members/AvatarGroup/ProfileAvatar";
export default {
  components: {
    ProfileAvatar
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      menuMaxHeight: `${60 * 5 + 4}px`,
      stackedLimit: 6,
      stackedMenu: false
    };
  },
  computed: {
    avatarsSorted() {
      let data = this.data;
      return data.length && data.length > 0
        ? data.sort((a, b) => a.name.localeCompare(b.name))
        : null;
    },
    avatarsStackedLimited() {
      return this.avatarsSorted && this.avatarsSorted.length > 0
        ? this.avatarsSorted.slice(0, this.stackedLimit)
        : null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
