<template>
  <div>
    <infinite-loading
      ref="infiniteLoading"
      :distance="50"
      spinner="waveDots"
      @infinite="infiniteHandler"
    >
      <span slot="no-more"></span>
      <span slot="no-results"> </span>
    </infinite-loading>
  </div>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: { InfiniteLoading },
  props: {
    endpoint: {
      type: String,
      default: "",
    },
    reset: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No data available at the moment",
    },
    hideNoResults: {
      type: Boolean,
      default: false,
    },
    distance: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      lastId: "",
      infiniteLoading: false,
    };
  },
  watch: {
    reset() {
      this.triggerInfiniteHandler();
    },
    infiniteLoading(val) {
      this.$emit("update-loading", val);
    },
  },
  methods: {
    infiniteHandler($state) {
      if (this.infiniteLoading === true) return;
      this.infiniteLoading = true;
      this.$store
        .dispatch(this.endpoint, { lastId: this.lastId })
        .then(async ({ data }) => {
          if (data[0].data.length) {
            this.$emit("setInfiniteResponse", data[0]);
          }
          if (data[0].meta.total > 10) {
            await this.extractLastId(data[0].data);
            $state.loaded();
          } else {
            $state.complete();
          }
          this.infiniteLoading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
          $state.complete();
          this.infiniteLoading = false;
        });
    },
    extractLastId(data) {
      if (data && data.length) {
        this.lastId = data[data.length - 1].id;
      }
    },
    triggerInfiniteHandler() {
      this.lastId = "";
      this.infiniteLoading = false;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.$emit("resetInfiniteResponse");
    },
  },
};
</script>
